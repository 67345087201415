import React, { useRef, useState } from 'react';
import { FaCirclePlay } from "react-icons/fa6";

const App = () => {
  const videoRef = useRef(null);
  const [showNotification, setShowNotification] = useState(false);

  const handleShareClick = () => {
    // Copy text to clipboard s
    navigator.clipboard.writeText('https://www.hulky.xyz')
      .then(() => {
        // Show notification
        setShowNotification(true);
        // Hide notification after 3 seconds
        setTimeout(() => setShowNotification(false), 3000);
      })
      .catch((err) => {
        console.error('Failed to copy: ', err);
      });
  };

  const handlePlayVideo = () => {
    if (videoRef.current) {
      videoRef.current.currentTime = 0;
      videoRef.current.play();
    }
  };


  return (
    <div className="relative w-full h-screen">
      <div className="relative w-full h-[50%] overflow-hidden">
        <video
          ref={videoRef}
          className="absolute top-0 left-0 w-full h-full object-cover md:object-contain"
          playsInline
          onClick={handlePlayVideo}
        >
          <source src="/videos/hulky_black_bg.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <img
          className="absolute top-4 right-0 sm:top-8 sm:right-4 lg:right-20 w-36 sm:w-56 md:w-72 sm:m-4"
          src="/images/hulky_img.png"
          alt="Hulky"
        />
        <a href="https://pump.fun/8YqFWNR6z13D9nprBeDedEQsJG3vdTw8fdvFQTcWpump" target="_blank" rel="noopener noreferrer">
          <img
            className="absolute top-4 left-2 sm:left-4 w-24  sm:w-44 sm:h-44 rotate-y"
            src="/icons/pump_fun_icon.png"
            alt="X"
          />
        </a>
        <a href="https://x.com/hulky_sol" target="_blank" rel="noopener noreferrer">
          <img
            className="absolute top-32 sm:top-48 left-6 sm:left-14 w-16 h-16 sm:w-24 sm:h-24 rotate-y"
            src="/icons/twitter_icon.png"
            alt="X"
          />
        </a>
      </div>
      <div className="flex flex-col items-center mt-8">
        <div>
          <button
            className=" mb-8 absolute top-2/3 mx-auto transform -translate-x-1/2 -translate-y-1/2 px-4 py-2 text-4xl uppercase font-extrabold bg-green-500 rounded-full hover:bg-opacity-100 flex items-center space-x-2 button-85 shake"
            onClick={handlePlayVideo}
          >
            <FaCirclePlay className="text-black mr-1" />
            Play
          </button>
        </div>
        <div className="heartbeat">
          <button
            onClick={handleShareClick}
            className="text-5xl uppercase font-extrabold bg-green-700 text-white py-1 px-6"
          >
            Share
          </button>

          {/* Notification */}
          {showNotification && (
            <div className="absolute left-1/2 transform -translate-x-1/2  text-center w-44 bg-gray-800 text-white py-2 px-4 rounded-md text-sm font-normal">
              Link copied to clipboard!
            </div>
          )}
        </div>

        <div className="relative">
          <img
            className="w-64"
            src="/images/ca.png"
            alt="CA"
          />
          <div className="absolute inset-0 flex items-center justify-center">
            <div className="sm:mt-7 bg-black text-white px-4 py-1 text-sm md:text-2xl transform -translate-y-1/2">
              8YqFWNR6z13D9nprBeDedEQsJG3vdTw8fdvFQTcWpump
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default App;
